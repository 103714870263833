import request from "@/Rest";
import { IN_APP_PURCHASE_URLS } from "@/Rest/api/urls";

export async function getAppStoreSubscriptionInfo() {
  try {
    const { data } = await request.GET(
      IN_APP_PURCHASE_URLS.getAppStoreSubscriptionInfo
    );

    return data;
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function getGooglePlayStoreSubscriptionInfo() {
  try {
    const { data } = await request.GET(
      IN_APP_PURCHASE_URLS.getGooglePlayStoreSubscriptionInfo
    );

    return data;
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function inAppPurchaseDeactivateUsers(
  dependentsIds,
  deactivateAllDependents,
  platform
) {
  try {
    const payload = {
      dependents_ids: dependentsIds,
      deactivate_all_dependents: deactivateAllDependents,
      platform,
    };
    await request.POST(
      IN_APP_PURCHASE_URLS.inAppPurchaseDeactivateUsers,
      payload
    );
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}

export async function inAppPurchasedependentsToAddOrReactivate() {
  try {
    const { data } = await request.GET(
      IN_APP_PURCHASE_URLS.inAppPurchasedependentsToAddOrReactivate
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function inAppPurchaseReactivateUsers(
  dependentsIds,
  reactivateAllDependents
) {
  try {
    const payload = {
      dependents_ids: dependentsIds,
      reactivate_all_dependents: reactivateAllDependents,
    };
    await request.POST(
      IN_APP_PURCHASE_URLS.inAppPurchaseReactivateUsers,
      payload
    );
  } catch (error) {
    const errorMessage = error.response.data.message;
    throw new Error(errorMessage);
  }
}
