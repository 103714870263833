<template>
  <Modal
    id="activate subscription"
    :isConfirmDisabled="!isCardValid || isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    :confirmationText="confirmationText"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Payment information</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div>
        <h1 class="mt-4">
          Please enter all the information requested for your payment.
        </h1>
      </div>
      <form id="stripe" @submit.prevent="handleSubmit" class="mt-5">
        <h5 class="font-semibold mb-2">Name on card</h5>
        <div class="mt-1">
          <input
            class="mt-1 block w-full p-2 bg-grey-steps"
            type="text"
            placeholder="Name on card"
            :disabled="false"
            @input="validateCardName"
            v-model="nameOnCard"
          />
        </div>
        <h5 class="font-semibold pt-2">Card details</h5>
        <div class="">
          <div class="my-2 border p-4" ref="cardElement"></div>
        </div>
        <p class="text-danger">{{ stripeValidationError }}</p>
      </form>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch, nextTick } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";
import stripe from "@/plugins/stripe";

const props = defineProps({
  show: Boolean,
  subscriptionExpiryDate: String,
  isLoading: Boolean,
  confirmationText: {
    type: String,
    default: "Confirm"
  }
});

const emit = defineEmits(["update:show", "confirm"]);

const stripeValidationError = ref("");
const cardElement = ref(null);
const nameOnCard = ref("");
const cardObj = ref(null);
const isCardValid = ref(false);
const isCardDetailsValid = ref(false);
const isNameOnCardValid = ref(false);
const isConfirmDisabled = ref(false);

function handleConfirm() {
  emit("confirm", {
    card: cardObj.value,
    name: nameOnCard.value,
  });
}

function handleCancel() {
  isCardValid.value = false;
  stripeValidationError.value = "";
  emit("update:show", false);
}

function initializateStripeElements() {
  const elements = stripe.elements();
  const style = {
    base: {
      iconColor: "black",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      ":-webkit-autofill": {
        color: "#F7F9FB",
      },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  };

  cardObj.value = elements.create("card", { style });
  cardObj.value.mount(cardElement.value);
  cardObj.value.on("change", setValidationError);
}

function setValidationError(event) {
  stripeValidationError.value = event.error ? event.error.message : "";
  isCardDetailsValid.value = event.complete;
  validateCard();
}

function validateCardName(event) {
  isNameOnCardValid.value = event.target.value.trim() !== "";
  validateCard();
}

function validateCard() {
  isCardValid.value = isNameOnCardValid.value && isCardDetailsValid.value;
}

watch(
  () => props.show,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        initializateStripeElements();
      });
    }
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
